import { splitSdk } from '@splitsoftware/splitio-redux'

/**
 * Our own record of shared clients. The redux SDK already keeps a list of
 * shared clients on `splitSdk.sharedClients` but unless we instantiate the
 * client ourselves we don't have the ability to listen for the `SDK_READY`
 * event using the Redux SDK. The `isReady` property exposed on the Redux state
 * by the Split Redux SDK only tells us if the *main* client[1] is ready.
 *
 * [1] The main client in our app is the client for the anonymous traffic type
 *     which is used for flags that are relevant before users are logged in
 *     (e.g. for triage flow etc).
 */
const sharedClientMap = {}

/**
 * Helper for getting a shared Split client. This should only be used for access
 * to clients that are not the main client (the client created automatically by
 * the Redux SDK when we call `initSplitSdk` in common/store/initSplitSdk.js).
 *
 * If a client for the provided key does not exist, it will be created. Clients
 * are created using the `splitSdk.factory.client` method which means that they
 * automatically have the same configuration as the main client (see
 * common/store/initSplitSdk.js).
 *
 * We do not need to use these clients for sending events to Split. The `track`
 * method from `@splitsoftware/splitio-redux` can be used so long as you provide
 * the correct `trafficType` and `key` in the `track` method's options. This
 * keeps the tracking code simpler as you do not need to worry about which
 * client to call track on.
 *
 * @param {string} key Identifier for the the user (e.g. user._id for the client
 *  for the `user` traffic type).
 * @param {Object} options
 * @param {Function} options.setClientState Optional function which will be
 *  called with the new client state when the client is ready or times out. The
 *  intention is that the function passed here is a setState function from
 *  React's useState hook.
 * @returns {{client: Object, isReady: Promise<void>}} An object containing the
 *  Split client and a promise that resolves when the client is ready.
 */
export const getClient = (key, { setClientState } = {}) => {
  if (sharedClientMap[key]) return sharedClientMap[key]

  if (!splitSdk.factory) {
    throw new Error(
      'Ensure Split SDK has been initialised before calling getClient'
    )
  }

  const client = splitSdk.factory.client(key)

  sharedClientMap[key] = {
    client,
    isReady: false,
    isReadyPromise: new Promise((resolve) => {
      client.on(client.Event.SDK_READY, () => {
        resolve()

        const state = {
          ...sharedClientMap[key],
          isReady: true,
          isTimedOut: false,
        }

        sharedClientMap[key] = state

        if (setClientState) {
          setClientState(state)
        }
      })
    }),
    isTimedOut: false,
    isTimedOutPromise: new Promise((resolve) => {
      client.on(client.Event.SDK_READY_TIMED_OUT, () => {
        resolve()

        const state = {
          ...sharedClientMap[key],
          isReady: false,
          isTimedOut: true,
        }

        sharedClientMap[key] = state

        if (setClientState) {
          setClientState(state)
        }
      })
    }),
  }

  return sharedClientMap[key]
}
