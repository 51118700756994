import React from 'react'
import loadable from '@loadable/component'

import applyLayout from 'startup/common/applyLayout'
import FallbackLayout from 'ui/layouts/FallbackLayout'

/* eslint-disable-next-line react/display-name */
export default (layoutConfig) => (routeProps) =>
  React.createElement(
    loadable(
      () =>
        import(/* webpackChunkName: "Funeral" */ './routes').then(applyLayout),
      {
        fallback: (
          <FallbackLayout layoutConfig={layoutConfig} routeProps={routeProps} />
        ),
      }
    ),
    { layoutConfig, routeProps }
  )
