import { connect } from 'react-redux'

import Footer from './component'

const mapStateToProps = (state) => ({
  marketingSiteUrl: state.config.MARKETING_SITE_URL,
})

const mapDispatchToProps = null

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
