import { connect } from 'react-redux'

import { isLoggedInWillsUser, getUser } from 'redux/user/selectors'
import { isLoggedIn } from 'redux/account/selectors'
import { showAlert } from 'redux/alert/actionCreators'
import { trackingIdentify } from 'redux/tracking/actionCreators'
import history from 'startup/common/history'
import { SIGN_UP_START_PATH } from 'ui/routes/start/paths'

import MainLayout from './component'
import { getCookieBannerFeatureEnabled } from 'redux/config/selectors'

const mapStateToProps = (state) => ({
  isLoggedInWillsUser: isLoggedInWillsUser(state),
  isLoggedIn: isLoggedIn(state),
  isModalOpen: !state.modal.hide && !!state.modal.component,
  rootUrl: state.config.ROOT_URL,
  isProduction: state.config.RELEASE_ENV === 'production',
  isCookieBannerFeatureEnabled: getCookieBannerFeatureEnabled(state),
})

const mapDispatchToProps = {
  trackUser: () => (dispatch, getState) => {
    const state = getState()
    const user = getUser(state)

    dispatch(trackingIdentify(user))
  },

  showAlert:
    ({ successAlert, errorAlert }) =>
    (dispatch) => {
      history.replace('/')

      if (successAlert) {
        return dispatch(showAlert('success', successAlert))
      }

      if (errorAlert) {
        return dispatch(showAlert('error', errorAlert))
      }
    },

  redirectToOnboardingFlow: () => () => {
    history.replace(SIGN_UP_START_PATH)
  },
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
