import { connect } from 'react-redux'

import { hideAlert } from 'redux/alert/actionCreators'

import Alert from './component'

const mapStateToProps = (state) => ({
  alert: state.alert,
})

const mapDispatchToProps = {
  hideAlert,
}

export default connect(mapStateToProps, mapDispatchToProps)(Alert)
