import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { GTR } from '@farewill/ui/tokens'

import FooterLink from '../FooterLink'

const StyledLogoContainer = styled(FooterLink)`
  margin-right: ${GTR.M};
  margin-bottom: ${GTR.XS};
`

const StyledLogo = styled.img`
  height: 35px;
  margin-top: ${GTR.XXS};
`

const FooterHomeLink = ({ loggedIn, hasWills, marketingSiteUrl }) => {
  const url = loggedIn
    ? hasWills
      ? '/wills'
      : '/summary'
    : `${marketingSiteUrl}/wills/overview`

  return (
    <StyledLogoContainer isExternal={!loggedIn} url={url}>
      <StyledLogo alt="Farewill logo" src="/images/logos/logo-text-white.svg" />
    </StyledLogoContainer>
  )
}

FooterHomeLink.propTypes = {
  loggedIn: PropTypes.bool,
  hasWills: PropTypes.bool,
  marketingSiteUrl: PropTypes.string.isRequired,
}

FooterHomeLink.defaultProps = {
  loggedIn: false,
  hasWills: false,
}

export default FooterHomeLink
