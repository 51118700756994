import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ICON_PATHS } from './constants'

import './styles.styl'

const Icon = ({
  name,
  className,
  width = 22,
  height = 22,
  color = 'dark',
  alt,
}) => (
  <svg
    aria-hidden
    className={cx('icon', `icon--${name}`, className)}
    height={height}
    viewBox="0 0 1024 1024"
    width={width}
  >
    <title>{alt || `${name} icon`}</title>
    <path className={'icon--' + color} d={ICON_PATHS[name]} />
  </svg>
)

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.oneOf([
    'black',
    'dark',
    'medium',
    'light',
    'success',
    'secondary',
    'tertiary',
  ]),
  alt: PropTypes.string,
}

Icon.defaultProps = {
  className: null,
  alt: null,
}

export default Icon
