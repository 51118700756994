import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR } from '@farewill/ui/tokens'

import { HeadInit } from 'ui/common/helpers'
import ModalLayout from 'ui/common/ModalLayout'
import Header from 'ui/common/header'
import Alert from 'ui/common/Alert'
import CookieBanner from 'ui/common/CookieBanner'
import Login from 'ui/routes/login/views/root'
import Footer from 'ui/common/Footer'
import { getQueryParameters } from 'ui/common/helpers'
import { LayoutConfigType } from 'types'
import { MAIN_CONTENT } from 'lib/zIndex'

const StyledRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index: ${MAIN_CONTENT};

  ${({ $isScrollDisabled }) =>
    $isScrollDisabled &&
    `
    height: 100vh;
    overflow: hidden;
    position: fixed;
  `}
`

const StyledContent = styled.div`
  ${({ $background }) =>
    `background-color: ${$background || COLOR.BACKGROUND.FOG}`};
  display: flex;
  flex: 1 0 auto;

  ${({ $verticallyCentered }) =>
    `align-items: ${$verticallyCentered ? 'center' : 'flex-start'};`}
`

class MainLayout extends Component {
  componentDidMount() {
    const { successAlert, errorAlert } = getQueryParameters(this.props.location)

    if (successAlert || errorAlert) {
      this.props.showAlert({ successAlert, errorAlert })
    }

    if (this.props.isLoggedInWillsUser) {
      this.props.trackUser()
    }

    const isLoggedInNonWillsUser =
      this.props.isLoggedIn && !this.props.isLoggedInWillsUser

    if (isLoggedInNonWillsUser && !this.props.config.isPublic) {
      this.props.redirectToOnboardingFlow()
    }
  }

  render() {
    const hasAccessToContent =
      this.props.isLoggedInWillsUser || this.props.config.isPublic

    return (
      <>
        <ModalLayout />
        <Alert />
        <StyledRoot
          $isScrollDisabled={this.props.isModalOpen}
          // TODO: Once we've upgraded to React 18 we can hopefully avoid the
          // need for this weird syntax required for us to be able to use the
          // inert attribute. See https://camchenry.com/blog/how-to-disable-ui-and-control-focus-with-the-inert-attribute
          inert={this.props.isModalOpen ? 'inert' : undefined}
        >
          <HeadInit
            config={this.props.config}
            isProduction={this.props.isProduction}
            rootUrl={this.props.rootUrl}
          />
          {!this.props.isCookieBannerFeatureEnabled && <CookieBanner />}
          <Header
            hideBorder={
              hasAccessToContent ? this.props.config.hideBorder : true
            }
            hideNavigation={this.props.config.hideNavigation}
            isPublic={this.props.config.isPublic}
          />
          <StyledContent
            $background={this.props.config.backgroundColor}
            $verticallyCentered={this.props.config.centerContentVertically}
          >
            {hasAccessToContent ? this.props.children : <Login />}
          </StyledContent>
          {!this.props.config.hideFooter && <Footer />}
        </StyledRoot>
      </>
    )
  }
}

MainLayout.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  isLoggedInWillsUser: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  config: PropTypes.shape(LayoutConfigType),
  rootUrl: PropTypes.string.isRequired,
  isProduction: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  showAlert: PropTypes.func.isRequired,
  trackUser: PropTypes.func.isRequired,
  redirectToOnboardingFlow: PropTypes.func.isRequired,
  isCookieBannerFeatureEnabled: PropTypes.bool.isRequired,
}

MainLayout.defaultProps = {
  config: {},
}

export default MainLayout
