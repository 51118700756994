import get from 'lodash/get'

import LoadingModal from 'ui/common/LoadingModal'

export const showModal = (data) => ({
  type: 'SHOW_MODAL',
  payload: data,
})

export const showLoadingModal = ({ text }) =>
  showModal({
    component: LoadingModal,
    keepOpen: true,
    overlayOnly: true,
    title: text,
  })

export const resetModal = () => ({ type: 'RESET_MODAL' })

export const closeModal = () => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({ type: 'HIDE_MODAL' })

    /**
     * Leaves time for transition out to complete before unmounting content
     */
    setTimeout(() => {
      dispatch(resetModal())
      resolve()
    }, 500)
  })
}

export const updateModalData = (data) => ({
  type: 'UPDATE_MODAL_DATA',
  payload: data,
})

export const removeModalOnSuccess = (response) => (dispatch) => {
  if (!get(response, ['update', 'error'])) {
    dispatch(resetModal())
  }
}
