import { connect } from 'react-redux'

import { isLoggedInWillsUser } from 'redux/user/selectors'
import HeaderHomeLink from './component'

const mapStateToProps = (state) => ({
  accountAppUrl: state.config.ACCOUNT_APP_URL,
  loggedIn: isLoggedInWillsUser(state),
  marketingSiteUrl: state.config.MARKETING_SITE_URL,
})

const mapDispatchToProps = null

export default connect(mapStateToProps, mapDispatchToProps)(HeaderHomeLink)
