const initialState = {
  hide: true,
}

export const modal = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SHOW_MODAL':
      return { ...payload, hide: false }

    case 'HIDE_MODAL':
      return { ...state, hide: true }

    case 'RESET_MODAL':
      return initialState

    case 'UPDATE_MODAL_DATA':
      return { ...state, ...payload }

    default:
      return state
  }
}
