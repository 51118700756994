import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FONT, COLOR } from '@farewill/ui/tokens'

const StyledLink = styled.a`
  text-decoration: none;
  font-size: ${FONT.SIZE.S};
  color: ${COLOR.WHITE};
`

const FooterLink = ({ className, isExternal, url, children }) => (
  <StyledLink
    as={isExternal ? 'a' : Link}
    className={className}
    href={url}
    to={url}
  >
    {children}
  </StyledLink>
)

FooterLink.propTypes = {
  className: PropTypes.string,
  isExternal: PropTypes.bool,
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

FooterLink.defaultProps = {
  className: null,
  isExternal: false,
}

export default FooterLink
