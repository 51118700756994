/**
 * FallbackLayout is shown while a route's js chunk is being loaded
 * It take the config from the route that is going to be navigated to
 * so shows the correct navigation and page styling
 * A spinning loader is revealed if the chunk hasn't loaded in 500ms
 *
 * See /common/ui/routes/account/index.js for example usage
 */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import MainLayout from 'ui/layouts/MainLayout'
import Loader from 'ui/common/loader'
import { LayoutConfigType, RoutePropsType } from 'types'

const LOADER_REVEAL_DELAY = 500

const FallbackLayout = ({ layoutConfig, routeProps }) => {
  const [showLoader, setShowLoader] = useState(false)
  useEffect(() => {
    const loaderTimeout = setTimeout(
      () => setShowLoader(true),
      LOADER_REVEAL_DELAY
    )
    return () => clearTimeout(loaderTimeout)
  }, [])

  return (
    <MainLayout config={layoutConfig} location={routeProps.location}>
      <Loader visible={showLoader} />
    </MainLayout>
  )
}

FallbackLayout.propTypes = {
  layoutConfig: PropTypes.shape(LayoutConfigType),
  routeProps: PropTypes.shape(RoutePropsType),
}

export default FallbackLayout
