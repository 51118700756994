import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormStatusMessage } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import isEmpty from 'lodash/fp/isEmpty'

import UnknownError from './components/UnknownError'
import Errors from './components/Errors'
import { API_ERROR_KEY, UNKNOWN_ERROR_TEXT } from './constants'

const StyledFormStatusMessage = styled(FormStatusMessage)`
  margin: 0 0 ${GTR.L};
`

const FormStatus = ({ errors, isSubmitting }) => {
  const wrapperEl = useRef()

  const showUnknownError = errors[API_ERROR_KEY] === UNKNOWN_ERROR_TEXT

  /**
   * The <FormStatus/> component should be focused on each time the
   * isSubmitting boolean goes from true to false (and the component
   * is visible).
   */
  useEffect(() => {
    if (!isSubmitting) wrapperEl.current?.focus()
  }, [isSubmitting])

  if (isEmpty(errors)) return null

  return (
    <div ref={wrapperEl} tabIndex={-1}>
      <StyledFormStatusMessage
        role="alert"
        status="error"
        title="Sorry, something went wrong"
      >
        {showUnknownError ? <UnknownError /> : <Errors errors={errors} />}
      </StyledFormStatusMessage>
    </div>
  )
}

FormStatus.propTypes = {
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
}

export default FormStatus
