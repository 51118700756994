import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { startCase } from 'lodash'
import { Wrapper, H, Button } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { useAutoFocus } from '../../../../client/hooks/useAutoFocus'

import Icon from 'ui/common/Icon'

const StyledHeader = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px ${COLOR.GREY.LIGHT};
`

const StyledCloseIcon = styled(Icon)`
  transform: rotate(45deg);
`

const StyledModal = styled.div`
  --modal-padding: ${GTR.M};
  background: var(--modal-content-background, ${COLOR.BACKGROUND.FOG});
  max-width: 100%;
  width: var(--modal-content-max-width, 500px);
`

const Modal = ({
  className,
  title,
  children,
  handleClose,
  hideHeader = false,
}) => {
  // TODO: Use `useId` hook for titleId when we upgrade to React 18 to avoid
  // the need for all this…
  const stripSpacesAndSpecialChars = (str) => str.replace(/[^a-zA-Z0-9]/g, '')
  const sanitisedTitle = stripSpacesAndSpecialChars(startCase(title))
  const titleId = `Modal-${sanitisedTitle}-Label`

  const modalRef = useRef()
  const previouslyFocusedElement = useRef(document.activeElement)

  useAutoFocus(modalRef)
  useEffect(() => {
    return () => {
      previouslyFocusedElement.current?.focus()
    }
  })

  return (
    <StyledModal
      ref={modalRef}
      aria-labelledby={titleId}
      className={className}
      role="dialog"
    >
      {!hideHeader && (
        <StyledHeader background={COLOR.WHITE} padding="M">
          <H id={titleId} margin={0} size="XS">
            {title}
          </H>
          <Button aria-label="Close dialog" type="button" onClick={handleClose}>
            <StyledCloseIcon color="medium" height={15} name="plus" />
          </Button>
        </StyledHeader>
      )}
      <Wrapper padding="var(--modal-padding)">{children}</Wrapper>
    </StyledModal>
  )
}

Modal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  hideHeader: PropTypes.bool,
}

Modal.defaultProps = {
  className: null,
}

export default Modal
