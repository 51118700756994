/* eslint-disable react/prop-types */
import React, { Component } from 'react'

class ClickOffWrapper extends Component {
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (event) => {
    if (this.props.inactive) return
    if (event.keyCode !== 27) return
    this.props.action()
  }

  handleClick = (event) => {
    if (this.props.inactive) return
    const target = event.target
    if (
      target &&
      target.classList &&
      target.classList.contains('click-off-wrapper-component')
    ) {
      this.props.action()
    }
  }

  render() {
    return (
      // FIXME: Remove the disable rule for
      // jsx-a11y/click-events-have-key-events when modal a11y is fixed. See
      // https://linear.app/farewill/issue/WILLA-31/improve-accessibility-of-modals-[medium]
      //
      // Disabling jsx-a11y/no-static-element-interactions here seems like a
      // reasonable thing to do as the backdrop should not be navigable by
      // keyboard users where it makes sense to provide a single way to close
      // the modal (e.g. the close modal button, which is keyboard navigable).
      //
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div className="click-off-wrapper-component" onClick={this.handleClick}>
        {this.props.children}
      </div>
    )
  }
}

export default ClickOffWrapper
