/**
 * To generate SVG JSON Paths use IcoMoon app
 */
export const ICON_PATHS = {
  edit: 'M783.66 0c-27.714 0-54.042 11.085-73.441 30.484l-597.217 597.218c-12.472 12.471-20.785 27.713-26.328 44.341l-84.525 289.603c-5.543 16.625 0 34.64 12.471 48.501 9.7 9.699 22.17 13.853 34.641 13.853 4.157 0 9.7 0 13.857-1.382l289.602-84.531c16.628-5.537 31.87-13.853 44.341-26.324l597.214-597.218c19.405-19.4 30.486-45.727 30.486-73.44s-11.081-54.041-30.486-73.44l-137.179-137.18c-19.397-19.399-45.722-30.484-73.435-30.484zM359.648 811.995l-146.88-146.88 401.84-401.84 146.882 146.88-401.842 401.841zM164.27 752.413l108.081 108.076-152.422 44.347 44.341-152.422zM927.766 241.104c0 1.385 0 2.771-1.382 5.542l-95.612 94.225-146.88-146.88 94.224-94.224c1.382-1.386 4.155-1.386 5.544-1.386 1.382 0 2.772 0 5.544 1.386l137.179 137.179c0 1.386 1.382 2.771 1.382 4.157z',
  loader:
    'M512 0c-282.8 0-512 229.23-512 512 0 282.767 229.2 512 512 512 282.798 0 512-229.233 512-512 0-28.277-22.902-51.2-51.2-51.2s-51.2 22.923-51.2 51.2c0 226.217-183.398 409.6-409.6 409.6-226.2 0-409.6-183.383-409.6-409.6 0-226.216 183.4-409.6 409.6-409.6 28.298 0 51.2-22.923 51.2-51.2s-22.902-51.2-51.2-51.2z',
  person:
    'M576.42 280.25c0 95.25-77.262 172.467-172.526 172.467-95.158 0-172.421-77.217-172.421-172.467 0-95.243 77.263-172.46 172.421-172.46 95.263 0 172.526 77.217 172.526 172.46zM532.421 529.362c90.106-46.592 151.789-140.658 151.789-249.112 0-154.777-125.472-280.25-280.315-280.25-154.737 0-280.211 125.473-280.211 280.25 0 107.691 60.737 201.191 149.79 248.118-86.421 31.264-147.158 92.844-188.316 162.309-56 96.121-78.421 212.749-85.158 285.243l107.369 9.944c6.21-67.633 22.737-161.194 70.526-240.23 44.421-74.924 112.842-131.228 226.105-131.228 111.053 0 183.367 62.653 230.735 143.996 48.424 83.187 65.687 178.817 65.687 222.494h107.789c0-61.429-21.682-175.982-80.314-276.712-42-72.181-105.37-140.611-195.476-174.822z',
  tick: 'M134.55 492.483l-111.562 111.231 373.198 374.31 841.133-843.64-111.562-111.231-729.571 731.745z',
  plus: 'M585.143 438.857v-365.214c0-40.733-32.747-73.644-73.143-73.644-40.677 0-73.143 32.971-73.143 73.644v365.214h-365.214c-40.733 0-73.644 32.747-73.644 73.143 0 40.677 32.971 73.143 73.644 73.143h365.214v365.214c0 40.733 32.747 73.644 73.143 73.644 40.677 0 73.143-32.971 73.143-73.644v-365.214h365.214c40.733 0 73.644-32.747 73.644-73.143 0-40.677-32.971-73.143-73.644-73.143h-365.214z',
  bolt: 'M672 402.928h-265.245l105.245-402.928-512 621.072h265.245l-105.245 402.928z',
  warning:
    'M308.056 704.535h-139.766l-45.638-704.535h231.042l-45.638 704.535zM236.747 795.811c62.752 0 114.095 51.343 114.095 114.095s-51.343 114.095-114.095 114.095c-59.9 0-111.242-51.343-111.242-114.095s51.343-114.095 111.242-114.095z',
  clock:
    'M512 921.6c226.216 0 409.6-183.384 409.6-409.6s-183.384-409.6-409.6-409.6c-226.216 0-409.6 183.384-409.6 409.6s183.384 409.6 409.6 409.6zM563.2 495.795v-188.595c0-28.277-22.923-51.2-51.2-51.2s-51.2 22.923-51.2 51.2v204.8c0 1.345 0.052 2.677 0.154 3.995-1.992 17.714 5.337 35.964 20.788 47.19l124.265 90.284c22.877 16.621 54.895 11.549 71.516-11.327s11.549-54.895-11.327-71.516v0l-102.996-74.831zM512 1024c-282.77 0-512-229.23-512-512s229.23-512 512-512c282.77 0 512 229.23 512 512s-229.23 512-512 512z',
  caret:
    'M896 714.981l-655.946-655.946c-48.851-48.851-130.616-49.571-180.603 0.417-50.336 50.336-50.174 130.846-0.417 180.603l724.91 724.91c29.949 29.949 72.27 41.808 112.054 34.982 39.784 6.826 82.105-5.033 112.054-34.982l724.91-724.91c49.757-49.757 49.919-130.267-0.417-180.603-49.987-49.987-131.752-49.268-180.603-0.417l-655.946 655.946z',
  phone:
    'M262.023 441.889c110.402 179.237 184.004 250.936 368.008 358.476l147.203-143.39c75.174 18.708 170.072 44.386 246.241 44.083 25.39-0.197 50.676 24.535 51.362 48.161l-1.711 223.697c-0.105 24.739-25.595 49.572-51.469 51.082-634.26 1.090-1000.922-356.083-1021.534-975.11-2.105-23.919 23.389-48.753 48.778-48.855h251.518c27.771-1.045 53.058 23.587 59.085 38.631-6.439 83.895 30.080 186.229 49.725 259.881l-147.204 143.344z',
  search:
    'M577.057 577.057c105.236-105.236 105.236-275.857 0-381.093s-275.857-105.236-381.093 0c-105.236 105.236-105.236 275.857 0 381.093s275.857 105.236 381.093 0zM612.465 688.683c-147.77 110.786-358.328 98.984-492.72-35.408-147.33-147.33-147.33-386.2 0-533.531s386.2-147.33 533.531 0c134.391 134.391 146.194 344.95 35.408 492.72l269.467 269.467c21.047 21.047 21.047 55.171 0 76.219s-55.171 21.047-76.219 0l-269.467-269.467z',
  arrow:
    'M557 857.999v-810.374c0-26.303-21.323-47.625-47.625-47.625s-47.625 21.323-47.625 47.625v0 805.123l-190.953-190.953c-18.599-18.599-48.753-18.599-67.352 0s-18.599 48.753 0 67.352v0l256.705 256.705c1.876 8.61 6.16 16.801 12.854 23.494 10.655 10.655 25.102 15.206 38.996 13.653 13.894 1.553 28.342-2.998 38.996-13.653 6.694-6.694 10.978-14.884 12.854-23.494l256.705-256.705c18.599-18.599 18.599-48.753 0-67.352s-48.753-18.599-67.352 0l-196.203 196.203z',
  lock: 'M773.487 430.768h-19.459v-185.426c0-135.279-108.578-245.342-242.033-245.342s-242.033 110.062-242.033 245.342v185.426h-19.459c-53.504 0-96.903 43.97-96.903 98.227v396.777c0 54.258 43.388 98.227 96.903 98.227h522.995c53.504 0 96.903-43.97 96.903-98.227v-396.777c-0.011-54.258-43.398-98.227-96.913-98.227zM346.271 245.342c0-92.636 74.337-167.989 165.723-167.989s165.723 75.353 165.723 167.989v185.426h-331.446v-185.426zM511.995 834.761c-52.479 0-95.028-43.119-95.028-96.327s42.537-96.327 95.028-96.327c52.49 0 95.028 43.119 95.028 96.327s-42.548 96.327-95.028 96.327z',
  messenger:
    'M512 0c-282.752 0-512 212.224-512 474.069 0 149.248 74.411 282.197 190.677 369.237v180.693l174.421-95.659c46.592 12.8 95.829 19.797 146.901 19.797 282.752 0 512-212.267 512-474.027s-229.248-474.112-512-474.112zM562.816 638.421l-130.347-139.093-254.421 139.093 279.851-297.088 133.589 139.051 251.264-139.051-279.936 297.088z',
  twitter:
    'M1022.037 184.448c-37.76 16.597-78.080 27.904-120.533 33.067 43.264-26.069 76.544-67.157 92.288-116.181-40.576 23.68-85.547 40.917-133.419 50.517-38.229-40.917-92.715-66.517-153.216-66.517-115.925 0-209.92 93.995-209.92 209.792 0 16.64 1.92 32.64 5.419 47.957-174.507-8.235-329.173-92.032-432.683-218.709-18.219 30.805-28.416 66.603-28.416 105.6 0 72.96 37.12 137.088 93.355 174.763-34.432-1.109-66.816-10.581-95.061-26.283v2.603c0 101.76 72.235 186.624 168.363 205.952-17.621 4.736-36.224 7.296-55.296 7.296-13.397 0-26.24-1.28-39.083-3.669 26.923 83.328 104.32 144.085 196.437 145.792-71.68 56.277-162.517 89.813-260.352 89.813-16.64 0-33.237-0.981-49.92-2.859 93.397 59.477 203.435 94.251 322.432 94.251 386.304 0 597.291-319.829 597.291-596.736 0-8.917 0-17.92-0.64-26.88 41.003-29.397 76.8-66.56 104.96-108.715l-2.005-0.853z',
  linkedin:
    'M928 0h-832c-52.8 0-96 43.2-96 96v832c0 52.8 43.2 96 96 96h832c52.8 0 96-43.2 96-96v-832c0-52.8-43.2-96-96-96zM384 832h-128v-448h128v448zM320 320c-35.4 0-64-28.6-64-64s28.6-64 64-64c35.4 0 64 28.6 64 64s-28.6 64-64 64zM832 832h-128v-256c0-35.4-28.6-64-64-64s-64 28.6-64 64v256h-128v-448h128v79.4c26.4-36.2 66.8-79.4 112-79.4 79.6 0 144 71.6 144 160v288z',
  whatsapp:
    'M741.753 592.234c-12.692-6.286-74.507-36.335-86.019-40.484-11.511-4.233-19.945-6.286-28.378 6.286-8.307 12.363-32.51 40.4-39.805 48.698-7.379 8.172-14.716 8.801-27.239 3.143-12.65-6.286-53.256-19.488-101.325-62.234-37.443-33.317-62.574-74.178-69.996-86.751-7.337-12.573-0.801-19.488 5.482-25.774 5.735-5.658 12.692-14.459 19.017-21.918 6.156-7.585 8.18-12.615 12.523-20.787 4.217-8.801 2.066-15.716-1.054-21.96-3.162-6.286-28.336-67.892-38.877-92.451-10.12-24.475-20.535-21.373-28.336-21.373-7.253-0.629-15.644-0.629-24.077-0.629s-22.053 3.101-33.606 15.045c-11.511 12.573-44.064 42.747-44.064 103.724s45.118 120.069 51.4 128.869c6.283 8.172 88.76 133.898 215.047 187.961 30.107 12.573 53.551 20.116 71.851 26.361 30.107 9.513 57.557 8.172 79.272 5.071 24.203-3.814 74.507-30.216 85.007-59.762 10.752-29.546 10.752-54.062 7.59-59.72-3.12-5.658-11.385-8.801-24.035-14.459zM511.785 938.624h-0.687c-76.006 0-151.326-20.703-217.069-59.52l-15.459-9.23-161.031 42.052 43.156-157.21-10.263-16.174c-42.512-67.973-65.099-146.255-65.099-226.865 0-234.845 191.304-426.343 426.924-426.343 113.967 0 220.934 44.64 301.492 125.509 80.515 80.179 124.917 187.617 124.917 301.481-0.172 234.801-191.519 426.343-426.624 426.343zM875.52 147.157c-97.28-94.037-225.28-147.157-361.6-147.157-280.832 0-509.483 227.584-509.611 507.435 0 89.429 23.424 176.64 68.053 253.653l-72.363 262.912 270.293-70.485c74.496 40.235 158.293 61.611 243.627 61.739h0.256c280.96 0 509.696-227.669 509.824-507.563 0-135.509-52.907-263.040-149.12-358.869z',
  facebook:
    'M967.509 0h-911.019c-31.189 0-56.491 25.301-56.491 56.491v911.019c0 31.232 25.301 56.491 56.491 56.491h490.411v-396.544h-133.504v-154.496h133.504v-114.133c0-132.224 80.811-204.16 198.784-204.16 56.533 0 105.131 4.139 119.296 6.016v138.24h-81.963c-64 0-76.459 30.763-76.459 75.563v98.603h152.917l-19.84 154.88h-133.077v396.032h260.907c31.275 0 56.533-25.259 56.533-56.491v-911.019c0-31.189-25.259-56.491-56.491-56.491z',
  charity:
    'M69.32 568.228h112.373c8.428 28.092 12.642 57.589 12.642 92.707 0 101.137-60.401 174.182-154.513 262.673l87.089 96.922c57.591-36.523 105.35-49.163 161.537-49.163 70.233 0 105.35 49.163 216.318 49.163 95.517 0 158.729-49.163 217.723-158.726l-110.967-61.804c-39.332 66.020-74.448 80.065-112.374 80.065-63.21 0-84.28-49.163-161.536-49.163-36.522 0-85.684 18.261-126.42 44.948 60.4-51.974 115.182-125.013 115.182-237.386 0-26.692-2.81-49.163-7.024-70.236h186.82v-140.466h-228.96c-8.428-32.307-14.046-70.233-14.046-125.015 0-105.35 63.21-147.49 134.848-147.49 68.828 0 123.61 26.688 160.131 110.968l122.208-57.591c-43.548-125.015-146.087-193.844-282.34-193.844-168.56 0-280.933 116.587-280.933 286.552 0 44.949 9.833 91.303 19.665 126.42h-67.424v140.466z',
  organisation:
    'M682.667 170.667c0 52.25-23.444 99.021-60.444 130.326l206.444 383.443c7.999-1.166 16.333-1.769 24.667-1.769 94.219 0 170.667 76.407 170.667 170.667s-76.447 170.667-170.667 170.667c-94.219 0-170.667-76.407-170.667-170.667 0-55.262 26.22-104.397 66.998-135.583l-204.443-379.66c-10.778 2.125-21.889 3.243-33.222 3.243-13.666 0-26.889-1.597-39.555-4.618l-182.223 394.819c31.556 30.97 51.111 74.098 51.111 121.799 0 94.259-76.444 170.667-170.667 170.667s-170.667-76.407-170.667-170.667c0-94.259 76.444-170.667 170.667-170.667 15.667 0 30.889 2.116 45.334 6.081l180.888-392.047c-34.111-31.208-55.555-76.118-55.555-126.034 0-94.257 76.444-170.667 170.667-170.667 94.219 0 170.667 76.41 170.667 170.667z',
  info: 'M120.454 45.056c30.037 0 55.979 10.923 77.824 32.768s32.768 47.787 32.768 77.824c0 30.037-10.923 55.979-32.768 77.824s-47.787 32.768-77.824 32.768c-30.947 0-57.344-10.923-79.189-32.768s-32.768-47.787-32.768-77.824c0-30.037 10.923-55.979 32.768-77.824s48.242-32.768 79.189-32.768zM214.662 1024h-189.781v-682.667h189.781v682.667z',
  arrowRight:
    'M13.642 13.634c-18.182 18.176-18.182 47.648 0 65.824l430.205 430.205-430.227 430.201c-18.159 18.176-18.159 47.649 0 65.825 18.182 18.176 47.659 18.176 65.841 0l460.774-460.777c9.705-9.691 14.224-22.528 13.591-35.216 0.633-12.693-3.887-25.595-13.568-35.286l-460.798-460.776c-18.159-18.179-47.636-18.179-65.818 0z',
  chat: 'M512 64c282.77 0 512 186.25 512 416 0 229.752-229.23 416-512 416-27.156 0-53.81-1.734-79.824-5.044-109.978 109.978-241.25 129.7-368.176 132.596v-26.916c68.536-33.578 128-94.74 128-164.636 0-9.754-0.758-19.33-2.164-28.696-115.796-76.264-189.836-192.754-189.836-323.304 0-229.75 229.23-416 512-416z',
  play: 'M0 961.117V62.8827C0 13.0283 55.3144 -16.9354 97.073 10.2985L745.405 433.124C802.375 470.278 802.375 553.722 745.405 590.876L97.073 1013.7C55.3144 1040.94 0 1010.97 0 961.117Z',
  cross:
    'M0 860.503L159.352 1019.86L508.65 670.557L862.093 1024L1021.45 864.648L668.003 511.205L1019.86 159.352L860.503 0L508.65 351.852L160.943 4.14512L1.59066 163.497L349.298 511.205L0 860.503Z',
  church:
    'M438.857 0h-146.286v292.571h-292.571v146.286h292.571v585.143h146.286v-585.143h292.571v-146.286h-292.571v-292.571z',
}
