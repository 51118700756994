/* eslint-disable farewill-wills-fe/no-direct-splitio-track-calls */
import { track } from '@splitsoftware/splitio-redux'
import * as Sentry from '@sentry/browser'

import { getClient } from './getClient'
import { TRAFFIC_TYPES } from './constants'

/**
 * Helper function for tracking events in Split. We should *always* use this
 * helper over directly calling the `track` method in the Split Redux SDK
 * elsewhere in our application to prevent bugs that result in infinite loading
 * states.
 *
 * The underlying cause of this issue is that the Split Redux SDK does not
 * provide a way for us to be notified, via a promise, of the ready state of
 * secondary clients that are automatically created by the Split Redux SDK (if
 * a client for that key does not already exist) when the `track` method is
 * called.
 *
 * We raised the issue of not being able to retrieve the ready state for
 * secondary clients with Split in their Slack[1] here. As a result they added
 * some new helper functions (`getStatus`)[2] that allows you to retrieve the
 * ready state but only via Redux, and not as a promise. This only solved half
 * of our problem as we have places in our application where we need to wait on
 * the ready state of a client within contexts outside of Redux (e.g. within an
 * async action).
 *
 * [1] https://splitcommunity.slack.com/archives/CAC4CD9MZ/p1710870460637079
 * [2] https://github.com/splitio/redux-client/issues/9#issuecomment-2130311307
 *
 * @param {Object} event Split event object
 */
export async function trackInSplit(event) {
  // We must make sure that we are instantiating the `user` Split client
  // ourselves before calling Splits track method to prevent the client
  // from being created automatically when the track method is called.
  // This is because the Redux SDK currently does not provide a way for
  // us to retrieve a "shared" client's ready state or wait on its ready
  // promise.
  // See https://farewill.slack.com/archives/C054TAF3R5Z/p1710926894881879
  const trafficTypeForDefaultClient = TRAFFIC_TYPES.ANONYMOUS
  const eventTrafficType = event.trafficType || trafficTypeForDefaultClient

  if (eventTrafficType !== trafficTypeForDefaultClient) {
    if (!event.key) {
      return Sentry.captureException(
        'No key provided for event in trackInSplit'
      )
    }

    const { isReady, isTimedOut } = getClient(event.key)
    await Promise.race([isReady, isTimedOut])
    return track(event)
  }

  return track(event)
}
