import { omit } from 'lodash'

export const initialUser = {
  executors: [],
  funeralRestingPlaceAdditionalWishes: [],
}

export const user = (state = initialUser, { type, payload }) => {
  switch (type) {
    case 'CHANGE_USER_FULL_LEGAL_NAME':
      return {
        ...state,
        fullLegalName: payload,
      }
    case 'CHANGE_USER_EMAIL':
      return {
        ...state,
        email: payload,
      }
    case 'CHANGE_USER_PREFERRED_NAME':
      return {
        ...state,
        preferredName: payload,
      }
    case 'CHANGE_USER_DOB':
      return {
        ...state,
        DOB: payload,
      }
    case 'CHANGE_USER_ADDRESS_LINE_1':
      return {
        ...state,
        addressLine1: payload,
      }
    case 'CHANGE_USER_ADDRESS_LINE_2':
      return {
        ...state,
        addressLine2: payload,
      }
    case 'CHANGE_USER_CITY':
      return {
        ...state,
        city: payload,
      }
    case 'CHANGE_USER_POSTCODE':
      return {
        ...state,
        postCode: payload,
      }
    case 'CHANGE_USER_PHONE_NUMBER':
      return {
        ...state,
        phoneNumber: payload,
      }
    case 'CHANGE_USER_FUNERAL_RESTING_PLACE':
      return {
        ...state,
        funeralRestingPlace: payload,
      }
    case 'CHANGE_USER_FUNERAL_RESTING_PLACE_ADDITIONAL_WISHES':
      return {
        ...state,
        funeralRestingPlaceAdditionalWishes: payload,
      }
    case 'RESET_USER_FUNERAL_RESTING_PLACE_ADDITIONAL_WISHES':
      return {
        ...state,
        funeralRestingPlaceAdditionalWishes:
          initialUser.funeralRestingPlaceAdditionalWishes,
      }
    case 'CHANGE_USER_FUNERAL_ADDITIONAL_WISHES':
      return {
        ...state,
        funeralAdditionalWishes: payload,
      }
    case 'CHANGE_USER_FUNERAL_SPECIFIC_WISHES':
      return {
        ...state,
        funeralSpecificWishes: payload,
      }
    case 'CHANGE_USER_FAREWILL_TRUSTEES_IS_AN_EXECUTOR':
      return {
        ...state,
        farewillTrusteesIsAnExecutor: payload,
      }
    case 'CHANGE_USER_RELATIONSHIP':
      return {
        ...state,
        relationshipStatus: payload.relationshipStatus,
        relationshipId: payload.relationshipId,
        inRelationship: payload.inRelationship,
      }
    case 'CHANGE_USER_MARKETING':
      return {
        ...state,
        marketingSource: payload.marketingSource,
        marketingSourceSpecifics: payload.marketingSourceSpecifics,
      }
    case 'CHANGE_USER_EXECUTORS':
      return {
        ...state,
        executors: payload,
      }
    case 'SET_USER_POLICY_ACCEPTANCE':
      return {
        ...state,
        termsAcceptedAt: payload.termsAcceptedAt || state.termsAcceptedAt,
        privacyPolicyAcceptedAt:
          payload.privacyPolicyAcceptedAt || state.privacyPolicyAcceptedAt,
      }
    case 'REMOVE_AUTO_APPLIED_VOUCHER_CODE':
      return omit(state, 'autoAppliedVoucherCode')
    case 'REMOVE_FUNERAL_WISHES':
      return {
        ...omit(state, [
          'funeralAdditionalWishes',
          'funeralSpecificWishes',
          'funeralRestingPlace',
        ]),
        funeralRestingPlaceAdditionalWishes: [],
      }
    case 'SET_REDEMPTION_PARTNER_DETAILS':
      return {
        ...state,
        redemptionPartnerName: payload?.partnerName || null,
      }
    case 'SET_REDEMPTION_PARTNER_MARKETING_PERMISSIONS':
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}
