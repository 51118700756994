import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ClickOffWrapper from './components/ClickOffWrapper'

const ModalLayout = ({
  component,
  componentProps,
  closeModal,
  hide,
  keepOpen,
  overlayOnly,
  direction,
}) => {
  let content = null
  if (component) {
    content = React.createElement(component, componentProps)
  }
  const show = !!(!hide && component)

  return (
    <>
      <div className={classNames('modal-overlay', { show })} />
      <div
        className={classNames('modal-component', {
          ['modal-component--' + direction]: direction,
          show,
          overlayOnly,
        })}
      >
        <ClickOffWrapper action={closeModal} inactive={!show || keepOpen}>
          <div className="content grid">{content}</div>
        </ClickOffWrapper>
      </div>
    </>
  )
}

ModalLayout.propTypes = {
  component: PropTypes.elementType,
  componentProps: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  hide: PropTypes.bool,
  keepOpen: PropTypes.bool,
  overlayOnly: PropTypes.bool,
  direction: PropTypes.string,
}

ModalLayout.defaultProps = {
  componentProps: {},
}

export default ModalLayout
