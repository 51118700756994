import history from 'startup/common/history'
import { getQueryParameters } from 'ui/common/helpers'
import { addToQuery } from 'redux/query/actionCreators'
import { getParsedCookie } from '../common/lib/storage/cookies/helpers'
import { getCookieConfig } from '../common/lib/storage/cookies/config'

const storeQueryParameters = (store) => {
  const { CookieNames } = getCookieConfig()
  let queryParameters = getQueryParameters(history.location)
  const trackingParameters = getParsedCookie(CookieNames.LAST_TOUCH_TRACKING)
  if (trackingParameters) {
    queryParameters = {
      ...queryParameters,
      utm_source: trackingParameters.utmSource,
      utm_medium: trackingParameters.utmMedium,
      utm_campaign: trackingParameters.utmCampaign,
      utm_channel: trackingParameters.utmChannel,
      utm_term: trackingParameters.utmTerm,
      utm_content: trackingParameters.utmContent,
      gclid: trackingParameters.googleClickId,
      landing_path: trackingParameters.landingPath,
      snowplow_id: trackingParameters.snowplowId,
    }
  }
  store.dispatch(addToQuery(queryParameters))
}

export default storeQueryParameters
