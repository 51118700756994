/* eslint-disable react/prop-types */
import React from 'react'
import Helmet from 'react-helmet'
import qs from 'qs'
import classNames from 'classnames'

import { keyHasValue } from 'startup/common/utilities'
import { formatQueryObject } from 'lib/formatting/query'

/**
 * Add modifier classes to a base class
 *
 * @param {string} base The base class which you want to add modifiers on
 * @param {object} modifiersObj Object containing classes to conditionally add.
 */
export const modifierClassNames = function (base, modifiersObj) {
  return classNames(
    Object.keys(modifiersObj).reduce(
      (acc, key) =>
        Object.assign({ [base + '--' + key]: modifiersObj[key] }, acc),
      { [base]: true }
    )
  )
}

/**
 * Initial head setting fuction function - This sets up the main structure of Head
 *
 * @param {object} props The default props for the <head> initiation
 */
export const HeadInit = ({
  titleTag,
  descriptionTag,
  rootUrl,
  config,
  isProduction,
}) => (
  <Helmet
    defaultTitle="Farewill"
    title={titleTag}
    titleTemplate="%s | Farewill"
  >
    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
    <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
    <meta
      content="100002848153936"
      prefix="og: http://ogp.me/ns#"
      property="fb:admins"
    />

    {
      /* farewill.com, www.farewill.com site verification */
      [
        'cT8MWSgAevS6GyxWk2egmB0qH5fQH86vYvhn16z49gU',
        'mXJ3j3EVPo-f5eRZGQ1Jufl_WlF4ttlqhjp8Gxaq9yo',
      ].map((el, i) => {
        return <meta key={i} content={el} name="google-site-verification" />
      })
    }

    <meta charSet="utf-8" />
    <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
    <meta content="telephone=no" name="format-detection" />

    {config && (config.noIndex || !config.isPublic || !isProduction) && (
      <meta content="noindex,nofollow" name="robots" />
    )}

    <link href="/apple-touch-icon.png" rel="apple-touch-icon" sizes="180x180" />

    <link href="/site.webmanifest" rel="manifest" />
    <link color="#333333" href="/safari-pinned-tab.svg" rel="mask-icon" />
    <meta content="#FFDF4E" name="msapplication-TileColor" />
    <meta content="#F9F9F9" name="theme-color" />

    <meta content="Farewill" name="author" />
    <meta content={descriptionTag} name="description" />

    <meta content={titleTag} property="og:title" />
    <meta content={descriptionTag} property="og:description" />
    <meta content="product" property="og:type" />
    <meta content={rootUrl} property="og:url" />
    <meta content={`${rootUrl}/images/logos/share.jpg`} property="og:image" />

    <meta content="summary" name="twitter:card" />
    <meta content="@farewill" name="twitter:site" />

    <link href="/images/logos/share.jpg" rel="image_src" />
  </Helmet>
)

/**
 * Head - Add/updates tags that go in the head tag
 *
 * @param {*object} props An object of props containing settings to set in the head
 */
export const Head = function (props) {
  return (
    <Helmet title={props.titleTag}>
      {props.descriptionTag && (
        <meta content={props.descriptionTag} name="description" />
      )}
      {props.authorTag && <meta content={props.authorTag} name="author" />}

      {props.titleTag && <meta content={props.titleTag} property="og:title" />}
      {props.descriptionTag && (
        <meta content={props.descriptionTag} property="og:description" />
      )}
      {props.typeTag && <meta content={props.typeTag} property="og:type" />}
      {props.urlTag && <meta content={props.urlTag} property="og:url" />}
      {props.imageTag && <meta content={props.imageTag} property="og:image" />}

      {props.imageTag && (
        <meta content="summary_large_image" name="twitter:card" />
      )}
      {props.imageTag && <meta content={props.imageTag} name="twitter:image" />}
      {props.imageAltTag && (
        <meta content={props.imageAltTag} name="twitter:image:alt" />
      )}
      {props.authorTwitterTag && (
        <meta content={`@${props.authorTwitterTag}`} name="twitter:creator" />
      )}

      {props.imageTag && <link href={props.imageTag} rel="image_src" />}
      {props.canonical && <link href={props.canonical} rel="canonical" />}
    </Helmet>
  )
}

export const JSONLD = ({ data }) => {
  if (!data) {
    return null
  }

  return (
    <script
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(data),
      }}
      type="application/ld+json"
    />
  )
}

/**
 * Helper function which wraps a section within the grid
 *
 * @param {string} name ClassName of the section containing the grid
 * @param {children} content The content to be inserted in the grid
 */
export const sectionGrid = function (name, content) {
  return (
    <section className={name}>
      <div className="grid u-pad">{content}</div>
    </section>
  )
}

/**
 * Converts a string into a UK formatted phone number
 *
 * @param {*string} str The string to convert
 */
export const formatTelephoneNumber = (number) => {
  const firstPart = number.substr(0, 3)
  const secondPart = number.substr(3, 4)
  const thirdPart = number.substr(7, 4)
  return `${firstPart} ${secondPart} ${thirdPart}`
}

/**
 * Converts a number into a string describing tens of millions of pounds
 *
 * @param {*string} str The string to convert
 */
export const formatCharityDonations = (number) =>
  `over £${Math.floor(number / 10000000) * 10} million`

/**
 * Returns a function that finds beneficiary name from id, given beneficiaries and charities
 *
 * @param {array} beneficiaries list of beneficiary objects
 * @param {array} charities list of charity objects
 */
export const beneficiaryNameById = (beneficiaries, charities) => {
  return (beneficiaryId) => {
    const recipient = beneficiaries
      .concat(charities)
      .find(keyHasValue('_id', beneficiaryId))
    if (!recipient) return
    return recipient.name
  }
}

/**
 * Checks whether localStorage exists and assigns it to 'localStorage' if it does.
 * From: https://mathiasbynens.be/notes/localstorage-pattern
 */
export const localStorage = (function () {
  var uid = new Date()
  var storage
  var result

  try {
    ;(storage = window.localStorage).setItem(uid, uid)
    result = storage.getItem(uid) == uid // eslint-disable-line eqeqeq
    storage.removeItem(uid)
    return result && storage
  } catch (exception) {} // eslint-disable-line
})()

/**
 * Returns true if user doesn't want to be tracked, false otherwise
 * Original code from: https://github.com/Ephys/do-not-track
 *
 * @param {string} header for do not track on a SSR
 * @returns {!boolean} The user agrees to being tracked.
 */
export const doNotTrack = function (doNotTrackHeader) {
  // if rendered via SSR with no header sent through then return false
  if (!doNotTrackHeader && typeof window === 'undefined') {
    return false
  }

  const doNotTrackOption =
    doNotTrackHeader ||
    window.doNotTrack ||
    window.navigator.doNotTrack ||
    window.navigator.msDoNotTrack

  if (!doNotTrackOption) {
    return false
  }

  if (doNotTrackOption.charAt(0) === '1' || doNotTrackOption === 'yes') {
    return true
  }

  if (doNotTrackOption.charAt(0) === '0' || doNotTrackOption === 'no') {
    return false
  }
}

/**
 * Returns true if URL passed in `referrer` is a Farewill-owned page.
 *
 * @param {string} referrer referring URL
 * @returns boolean
 */
export const isInternalReferral = (referrer) =>
  referrer.includes('farewill.com')

/**
 * Returns an object of query parameters from location
 *
 * @param {object} location A location object from history or passed down by react-router
 * @returns {object} key value paris of query parameters
 */
export const getQueryParameters = (location) => {
  if (!location.search) return {}

  const unformattedParameters = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  return formatQueryObject(unformattedParameters)
}
