import * as Sentry from '@sentry/browser'
import { serverCall } from 'startup/common/actionCreators'
import { trackEvent } from 'redux/tracking/actionCreators'

const recordProgress = (store, progressEvent, progressType) => {
  const url = `progress/${progressEvent}/${progressType}`

  serverCall(url, {}, store.dispatch, store.getState).catch((err) => {
    Sentry.captureException(err)
  })
}

export default (store) => (next) => (action) => {
  switch (action.type) {
    case 'PROGRESS':
      recordProgress(store, action.progressEvent, action.progressType)
      store.dispatch(
        trackEvent(`${action.progressEvent}_${action.progressType}`)
      )
      break
    default:
      break
  }

  return next(action)
}
