import React from 'react'
import PropTypes from 'prop-types'

import MainLayout from 'ui/layouts/MainLayout'
import { LayoutConfigType, RoutePropsType } from 'types'

/* eslint-disable-next-line react/display-name */
const applyLayout = ({ default: Component, customLayoutConfig }) => {
  const ComponentWithLayout = ({ layoutConfig, routeProps }) => (
    <MainLayout
      config={{ ...layoutConfig, ...customLayoutConfig }}
      location={routeProps.location}
    >
      <Component
        history={routeProps.history}
        location={routeProps.location}
        match={routeProps.match}
        staticContext={routeProps.staticContext}
      />
    </MainLayout>
  )

  ComponentWithLayout.propTypes = {
    layoutConfig: PropTypes.shape(LayoutConfigType),
    routeProps: PropTypes.shape(RoutePropsType),
  }

  return ComponentWithLayout
}

export default applyLayout
